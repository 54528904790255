<template>
  <front-layout :title="$t('Create User')">
    <div class="mb-8">
      <breadcrumb :name="$t('Create')" :previous-name="$t('Users')" :previous-url="route('front.manager.users')" />
    </div>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.first_name" :error="form.errors.first_name" :label="$t('First name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.last_name" :error="form.errors.last_name" :label="$t('Last name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input
              v-model="form.password"
              autocomplete="new-password"
              :disabled="!form.set_password"
              :error="form.errors.password"
              :label="$t('Password')"
              type="password"
            />
            <label class="mt-2 flex select-none items-center" for="set-password">
              <input
                id="set-password"
                v-model="form.set_password"
                class="form-checkbox mr-1"
                type="checkbox"
                @click="setPasswordClicked"
              />
              <span class="text-sm">{{ $t('Set password') }}</span>
            </label>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.locale" :error="form.errors.locale" :label="$t('Preferred Locale/Language')">
              <option :value="null" />
              <option v-for="(locale, key) in locales" :key="key" :value="locale">
                {{ key }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <select-input v-model="form.timezone" :error="form.errors.timezone" :label="$t('Timezone')">
              <option :value="null" />
              <option v-for="(timezone, key) in timezones" :key="key" :value="timezone">
                {{ timezone }}
              </option>
            </select-input>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label">{{ $t('Groups:') }}</label>
            <Multiselect
              v-model="form.groups"
              deselect-label=""
              :disabled="!groups"
              :hide-label="true"
              :hide-selected="true"
              :label="$t('name')"
              :multiple="true"
              :name="$t('Groups')"
              :options="groups"
              :placeholder="$t('Select groups')"
              select-label=""
              track-by="id"
            >
              <template #noResult>{{ $t('No groups found.') }}</template>
              <template #noOptions>{{ $t('No groups found.') }}</template>
            </Multiselect>
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <organization-role-dropdown v-model="form.organization_role" :error="form.errors.organization_role" />
          </div>
        </div>
        <div class="flex flex-col items-center justify-between border-t border-gray-100 px-8 py-6 md:flex-row md:py-4">
          <div class="flex items-center">
            <input id="notify-manager" v-model="form.notify_manager" class="form-checkbox mr-3" type="checkbox" />
            <label for="notify-manager">{{ $t('Email myself login details') }}</label>
          </div>
          <front-loading-button :loading="form.processing" type="submit">{{ $t('Create User') }}</front-loading-button>
        </div>
      </form>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import OrganizationRoleDropdown from '@/Shared/OrganizationRoleDropdown.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    OrganizationRoleDropdown,
    SelectInput,
    TextInput,
  },
  props: {
    locales: Object,
    timezones: Object,
    groups: Array,
    defaultRole: String,
  },
  data() {
    return {
      form: useForm(
        {
          first_name: null,
          last_name: null,
          email: null,
          username: null,
          phone: null,
          password: null,
          locale: 'en',
          timezone: null,
          groups: null,
          notify_manager: false,
          organization_role: this.defaultRole,
          set_password: false,
        },
        'UsersCreate'
      ),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          groups: data.groups ? data.groups.map((group) => group.id) : [],
        }))
        .post(this.route('front.manager.users.store'))
    },
    setPasswordClicked(event) {
      if (!event.target.checked) {
        this.form.password = null
      }
    },
  },
}
</script>
